import * as React from "react";
import { useMandateList } from "./useMandateList";
import { ContactId } from "../common/typings/contacts.typings";
import { ThirdId } from "../api/maestro/maestro.typings";
import { useGenerateId } from "../common/hooks/useGenerateId";
import { FetchFn, useSgConnectFetch } from "@sg-widgets/react-core";
import { SGMContactScope } from "../common/sgConnectScopes";

import { map, reduce } from "lodash-es";
import {
  ContactPerimeterType,
  Mandate,
  MandateAccount,
  MandateContact,
  MandateListColumnType,
} from "../common/typings/mandate.typing";
import Loading from "../common/components/Loading/Loading";
import UserMessage from "../common/components/UserMessage/UserMessage";
import { isStringEmpty } from "../utils/strings/stringUtils";
import { FormattedMessage } from "react-intl";

const getHiddenColumns = (hiddenColumn: MandateListColumnType[]): Record<MandateListColumnType | string, boolean> =>
  reduce(
    hiddenColumn,
    function(acc, column) {
      acc[`${column.trim().toLowerCase()}`] = true;
      return acc;
    },
    {}
  );

interface Props {
  id?: string;
  contactId: ContactId;
  thirdId: ThirdId;
  accountId: string;
  contactPerimeter: ContactPerimeterType;
  roleId?: string;
  functionId?: string;
  delegationId?: string;
  roleItemIds?: string
  contactEmail?: string;
  hiddenColumns: MandateListColumnType[];
  onReady?: () => void;
  onContactClick?: (contact: MandateContact) => void;
  onThirdClick?: (account: MandateAccount) => void;
  onMandateClick?: (mandate: Mandate) => void;
}

const MandateList: React.FC<Props> = ({
  id,
  contactId,
  thirdId,
  accountId,
  contactPerimeter,
  roleId,
  functionId,
  delegationId,
  roleItemIds,
  contactEmail,
  hiddenColumns,
  onReady,
  onContactClick,
  onThirdClick,
  onMandateClick
}: Props) => {
  const pickerId = useGenerateId(id);
  const hiddenColumnsConfig = getHiddenColumns(hiddenColumns);
  const fetch = useSgConnectFetch(SGMContactScope).fetch as FetchFn;

  const [mandates, isLoading, error] = useMandateList(fetch, contactId, thirdId, accountId, {
    businessFunctionId: functionId,
    roleId,
    delegationId,
    contactEmail,
    contactPerimeter,
    roleItemIds
  });

  React.useEffect(() => {
    onReady?.();
  }, [onReady]);

  return (
    <div id={pickerId}>
      <Loading isLoading={isLoading} />
      {!isLoading && error && (
        <UserMessage
          icon="info_outline"
          title="Unexpected error"
          message="We're unable to complete your request at the moment. Please try again or contact the support team"
          errorMessage={error}
          contactSupport={true}
        />
      )}
      {!isLoading && !error && mandates.length === 0 && <UserMessage icon="search" title="No mandates" message="" />}
      {!isLoading && !error && mandates?.length > 0 && (
        <table className="table table-responsive-lg">
          <thead>
            <tr>
              {!hiddenColumnsConfig?.contact && (
                <th scope="col">
                  <FormattedMessage id="global.mandatedContact" defaultMessage={"Mandated contact"} />
                </th>
              )}
              {!hiddenColumnsConfig?.jobtitle && (
                <th scope="col">
                  <FormattedMessage id="global.jobTitle" defaultMessage={"Job title"} />
                </th>
              )}
              {!hiddenColumnsConfig?.delegation && (
                <th scope="col">
                  <FormattedMessage id="global.delegation" defaultMessage={"Delegation"} />
                </th>
              )}
              {!hiddenColumnsConfig?.["function"] && (
                <th scope="col">
                  <FormattedMessage id="global.function" defaultMessage={"Function"} />
                </th>
              )}
              {!hiddenColumnsConfig?.role && (
                <th scope="col">
                  <FormattedMessage id="global.role" defaultMessage={"Role"} />
                </th>
              )}
              {!hiddenColumnsConfig?.third && (
                <th scope="col">
                  <FormattedMessage id="global.mandatedThirdParty" defaultMessage={"Mandated third party"} />
                </th>
              )}
              {!hiddenColumnsConfig?.inherit && (
                <th scope="col">
                  <FormattedMessage id="global.inherit" defaultMessage={"Inherit"} />
                </th>
              )}
            </tr>
          </thead>
          <tbody>
            {map(mandates, (mandate, index) => (
              <tr onClick={() => onMandateClick?.(mandate)} key={index}>
                {!hiddenColumnsConfig?.contact && (
                  <td>
                    <div className="w-100" onClick={() => onContactClick?.(mandate.contact)}>
                      <div className="w-100">{mandate.contact.fullName}</div>
                      <div className="text-secondary text-small">{mandate.contact.email}</div>
                    </div>
                  </td>
                )}
                {!hiddenColumnsConfig?.jobtitle && <td>{isStringEmpty(mandate.jobTitle) ? "-" : mandate.jobTitle}</td>}
                {!hiddenColumnsConfig?.delegation && (
                  <td>
                    <span className="badge badge-pill badge-discreet-primary">
                      {mandate.delegationId ? "Yes" : "No"}
                    </span>
                  </td>
                )}
                {!hiddenColumnsConfig?.["function"] && <td>{mandate.businessFunction?.friendlyName}</td>}
                {!hiddenColumnsConfig?.role && <td>{mandate.role?.name}</td>}
                {!hiddenColumnsConfig?.third && (
                  <td>
                    <div onClick={() => onThirdClick?.(mandate.account)}>
                      <span>{mandate.account.friendlyName} - </span>
                      {mandate.account.countryCode && (
                        <span className="text-secondary mx-1">{mandate.account.countryCode}</span>
                      )}
                      {mandate.account.level && <span className="text-secondary mx-1">{mandate.account.level}</span>}
                      {mandate.account.bdrId && (
                        <span className="text-secondary mx-1">{`BDR ${mandate.account.bdrId}`}</span>
                      )}
                    </div>
                  </td>
                )}
                {!hiddenColumnsConfig?.inherit && (
                  <td>
                    <span className="badge badge-pill badge-discreet-primary">
                      {mandate.account.isInherited ? "Yes" : "No"}
                    </span>
                  </td>
                )}
              </tr>
            ))}
          </tbody>
        </table>
      )}
    </div>
  );
};

export default MandateList;
